import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FileUploadService } from '../../services/file-upload/file-upload.service';
import { ConfigurationComponent } from "./configuration.component";
import { FileUploadComponent } from "../../components";



const routes: Routes = [
  { path: '', component: ConfigurationComponent }
];

@NgModule({
  declarations: [ConfigurationComponent, FileUploadComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes)
  ],
  providers: [FileUploadService],
  exports: [ConfigurationComponent]
})
export class ConfigurationModule { }
